<template>
    <code-highlight language="php" class="rounded">
        <code class="language-html language-prism-html-markup">
            {{ code }}
        </code>
    </code-highlight>
</template>
<script>
import CodeHighlight from "vue-code-highlight/src/CodeHighlight.vue";
import 'prism-es6/components/prism-markup-templating';
//import '../../../node_modules/vue-code-highlight/themes/window.css';
import "vue-code-highlight/themes/duotone-sea.css";

export default 
{
    components: { CodeHighlight },
    props:
    {
        code:
        {
            required:true,
            type: String
        }
    }
}
</script>
<style>
.token.keyword
{
    color:#1976d2!important;
    font-weight: 900!important;
}
.token.string
{
    color:#ffffff!important;
    font-weight: 100!important;
}
.token.operator
{
    color:#6cb4f0!important;
    font-weight: 100!important;
}
.token.function
{
    color:#008cff!important;
    font-weight: 100!important;
}
</style>